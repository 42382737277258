import * as React from "react"

import DoubleToolScreen from '../../../../components/doubleToolScreen'


const DoubleTool = ({ data }) => (
  <div>
    <DoubleToolScreen
      name="Miro"
      img_name="miro_logo.png"
      link="https://miro.com/"

      name2="Mural"
      img_name2="mural_logo.png"
      link2="https://www.mural.co/"
      
      description="Both Miro or Mural are great software for creating diagrams and user flows for low-fidelity designs."
      twitterHandleTool="@MiroHQ%20and%20@MURAL"
    />
  </div>
)

export default DoubleTool;


